import React from 'react';
import { Button, Popover, Slider } from 'antd';
import { PRIORITY_RANGE_MINIMUM, PRIORITY_RANGE_MAXIMUM } from '../../Constants/Constants';
import { CloseCircleOutlined } from '@ant-design/icons';

function PriorityPopover({
  priorityFilter,
  setPriorityFilter,
  handlePriorityFilter,
  handleClearPriority,
  popoverVisible,
  setPopoverVisible
}) {
  const priorityRange = [PRIORITY_RANGE_MINIMUM, PRIORITY_RANGE_MAXIMUM];

  return (
    <Popover
      className='ml'
      content={
        <div className='priority-popover-container'>
          <p>{priorityRange[0]}</p>
          <Slider
            className='slider-container'
            dots={false}
            defaultValue={priorityRange}
            min={priorityRange[0]}
            max={priorityRange[1]}
            onChange={value => {
              setPriorityFilter(value);
            }}
            value={priorityFilter}
            onAfterChange={handlePriorityFilter}
            range
          />
          <p>{priorityRange[1]}</p>
        </div>
      }
      title={
        <div className='priority-popover-title'>
          <Button type='text' size='small' onClick={handleClearPriority} icon={<CloseCircleOutlined />}></Button>
        </div>
      }
      trigger='click'
      visible={popoverVisible}
      onVisibleChange={visible => {
        setPopoverVisible(visible);
      }}>
      <Button
        className={
          priorityFilter[0] !== priorityRange[0] || priorityFilter[1] !== priorityRange[1]
            ? 'priority-filter-selected'
            : 'priority-filter-unselected'
        }>
        {priorityFilter[0] !== priorityRange[0] || priorityFilter[1] !== priorityRange[1] ? (
          <>
            Priority : {priorityFilter[0]} - {priorityFilter[1]}
          </>
        ) : (
          'Priority'
        )}
      </Button>
    </Popover>
  );
}

export default PriorityPopover;
