import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Skeleton } from 'antd';

import { BASE_URL as BASEURL } from '../../Constants/URLs';
import LayoutComponent from '../Components/Layout';
import { getAPI } from '../../Services/Api';
import { DisplayJson } from '../../Utils/HelperFunctions';

const InRaw = () => {
  const location = useLocation();
  const { id } = useParams();

  const [data, setData] = useState(location?.state || {});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!location?.state) {
      getData();
    }
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    setIsLoading(true);
    let url = BASEURL + '/request/' + id + '/output';
    getAPI(url)
      .then(res => {
        setData(res);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <LayoutComponent>
      <Skeleton loading={isLoading} active title round>
        <div className='render-json'>
          <DisplayJson data={data} />
        </div>
      </Skeleton>
    </LayoutComponent>
  );
};

export default InRaw;
