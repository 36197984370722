import { Button, Modal, message, Upload, Typography } from 'antd';
import React, { useState } from 'react';
import { InboxOutlined, FileZipFilled, CloseCircleOutlined } from '@ant-design/icons';
import { postAPI } from '../Services/Api';

import { BULK_PRIORITY_UPDATE } from '../Constants/URLs';

import { formatCurrentDate } from '../Utils/HelperFunctions';

const FileUploadModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadedFile, setuploadedFile] = useState({});
  const resetData = () => {
    setIsOpen(false);
    setIsUploaded(false);
    setuploadedFile({});
  };
  const handleDelete = () => {
    setuploadedFile({});
    setIsUploaded(false);
    message.error('File removed');
  };
  const { Dragger } = Upload;
  const props = {
    accept: '.csv',
    beforeUpload: file => {
      setIsOpen(false);
      setIsUploaded(true);
      setuploadedFile(file);
      return false;
    },
    showUploadList: false
  };
  const handleSubmit = async () => {
    try {
      let user = JSON.parse(sessionStorage.getItem('user'));
      setIsUploaded(false);
      let formData = new FormData();
      formData.append('file', uploadedFile);
      formData.append('user_email', user?.email);
      const res = await postAPI(BULK_PRIORITY_UPDATE, formData);
      message.success(res.message);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Button type='inherit' onClick={() => setIsOpen(true)} style={{ color: '#929292' }}>
        Bulk Priority Change
      </Button>
      <Modal visible={isOpen} title='Upload file' onCancel={() => resetData()} footer={[]}>
        <Dragger {...props}>
          <p className='ant-upload-drag-icon'>
            <InboxOutlined />
          </p>
          <p className='ant-upload-text'>Click or drag file to this area to upload</p>
        </Dragger>
      </Modal>
      <Modal visible={isUploaded} title='Upload file' onCancel={() => resetData()} footer={[]}>
        <div className='card-row-container'>
          <div className='card-info-container'>
            <FileZipFilled className='file-icon' />
            <div className='file-detail-container'>
              <Typography className='file-upper-detail'>{uploadedFile.name}</Typography>
              <div style={{ display: 'flex' }}>
                <Typography className='file-lower-detail'>{formatCurrentDate()}</Typography>
                <Typography className='file-lower-detail'>
                  <span style={{ fontWeight: 'bold', paddingLeft: '4px' }}>·</span>{' '}
                  {Math.floor(uploadedFile.size / 1000)} Kb
                </Typography>
              </div>
            </div>
          </div>
          <div className='close-circle-outlined-container'>
            <CloseCircleOutlined style={{ fontSize: '1.5rem' }} onClick={() => handleDelete()} />
          </div>
        </div>
        <div className='upload-button-container'>
          <Button type='primary' onClick={() => handleSubmit()}>
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default React.memo(FileUploadModal);
