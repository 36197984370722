import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from '../Stores/AuthContext';

function ProtectedRoute() {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();

  return isAuthenticated ? <Outlet /> : <Navigate to='/login' state={{ from: location.pathname }} />;
}

export default ProtectedRoute;
