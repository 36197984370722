import { LogoutOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import { useContext } from 'react';
import { AuthContext } from '../../Stores/AuthContext';

const HeaderContent = () => {
  const { userDetails, isAuthenticated, logoutHandler } = useContext(AuthContext);

  return (
    <Row className='navbar-container'>
      <Col xs={{ span: 22, offset: 1 }}>
        <div className='navbar-content'>
          <a href='/'>
            <img src='/img/logo-white.svg' alt='Attentive Logo' />
          </a>
          {isAuthenticated && (
            <span className='navbar-userdetails'>
              <p>{userDetails.email}</p>
              <Tooltip placement='bottom' title='Logout'>
                <Button type='primary' danger shape='circle' onClick={logoutHandler} icon={<LogoutOutlined />}></Button>
              </Tooltip>
            </span>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default HeaderContent;
