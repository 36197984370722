import React, { useContext, useEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { GOOGLE_LOGIN_CLIENT_ID } from '../Constants/URLs';
import { message, Spin } from 'antd';
import { postAPI } from '../Services/Api';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../Stores/AuthContext';

const Login = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const handleLoginCallback = res => {
    setIsLoading(true);

    let userObj = jwt_decode(res.credential);

    let payload = {
      email_id: userObj.email,
      unique_id: userObj.sub
    };
    postAPI('/login', payload)
      .then(res => {
        let obj = {
          id: userObj.sub,
          email: userObj.email
        };
        sessionStorage.setItem('user', JSON.stringify(obj));
        setIsAuthenticated(true);

        if (location.state?.from) {
          window.location.replace(location.state.from);
        } else {
          window.location.replace('/');
        }
      })
      .catch(err => {
        setIsAuthenticated(false);
        message.error('Cannot log you in');
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!isAuthenticated) {
      /* global google */
      google.accounts.id.initialize({
        client_id: GOOGLE_LOGIN_CLIENT_ID,
        callback: handleLoginCallback
      });

      google.accounts.id.renderButton(document.getElementById('signInDiv'), {
        theme: 'outline',
        size: 'large'
      });
    }

    // eslint-disable-next-line
  }, []);

  if (isAuthenticated) {
    return <Navigate to={location?.state?.from ?? '/'} />;
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div id='signInDiv' className='google_login_btn'></div>
      </Spin>
    </>
  );
};

export default Login;
