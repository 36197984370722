import { message } from 'antd';
import axios from 'axios';
import { API_KEY, BASE_URL } from '../Constants/URLs';

export const request = axios.create({
  baseURL: BASE_URL
});

const handleError = error => {
  if (error.message === 'Network Error') {
    message.error('Network error, not able to connect to server');
    return;
  }
  if (error.response.status === 404) {
    message.error(`Resource not found : ${error.response?.data}`, 1);
  } else if (error.response.status === 400) {
    if (error.response?.data?.error) message.error(`${error.response?.data?.error}`, 1);
    else message.error(`${error.response?.data}`, 1);
  } else if (error.response.status === 401) {
    message.error('UnAuthorised', 1);
    // handleLogout();
  } else if (error.response.status === 500) {
    message.error('Internal Server Error');
  } else {
    error.response.data.errors.forEach(item => message.error(item.message));
  }
};

const headers = {
  'x-api-key': API_KEY
};

export const getAPI = (endpoint, paramData = {}) => {
  return new Promise((resolve, reject) => {
    request
      .get(endpoint, {
        params: paramData,
        headers: {
          'Content-Type': 'multipart/form-data',
          ...headers
        }
      })
      .then(res => resolve(res.data))
      .catch(err => {
        handleError(err);
        reject(err);
      });
  });
};

export const postAPI = (endpoint, payload, config = {}) => {
  // add a conf=dition in headers and passs json everywhere
  let options = {};
  let headers = {
    'x-api-key': API_KEY
  };
  if (config?.multipartFormData) {
    options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers
      }
    };
  } else if (config?.loginAPI) {
    options = {
      headers: {
        'Content-Type': 'application/json',
        ...headers
      }
    };
  } else {
    options = {
      headers
    };
  }
  return new Promise((resolve, reject) => {
    request
      .post(endpoint, payload, { ...options })
      .then(response => resolve(response.data))
      .catch(error => {
        handleError(error);
        reject(error);
      });
  });
};

export const patchAPI = (endpoint, payload, config = {}) => {
  let options = {};
  if (config?.multipartFormData) {
    options = {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...headers
      }
    };
  } else {
    options = {
      headers
    };
  }

  return new Promise((resolve, reject) => {
    request
      .patch(endpoint, payload, { ...options })
      .then(res => resolve(res.data))
      .catch(err => {
        handleError(err);
        reject(err);
      });
  });
};

export const interpolate = function (theString, argumentArray) {
  var regex = /%s/;
  var _r = function (p, c) {
    return p.replace(regex, c);
  };
  return argumentArray.reduce(_r, theString);
};
