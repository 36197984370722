import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from './LoginPage/login';
import Home from './MainPage/Home/Home';
import InDetail from './MainPage/RequestData/InDetail';
import InRaw from './MainPage/RequestData/InRaw';
import ProtectedRoute from './Components/ProtectedRoute';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />

          <Route element={<ProtectedRoute />}>
            <Route path='/' element={<Home />} />
            <Route path='/request/in-detail/:id' element={<InDetail />} />
            <Route path='/request/raw/:id' element={<InRaw />} />
          </Route>

          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
