import React, { useState } from 'react';
import { REQUEST_STATUS_VALUES } from '../../Constants/Constants';
import { UPDATE_REQUEST_PRIORITY } from '../../Constants/URLs';
import { Button, Modal, InputNumber, message } from 'antd';
import { interpolate, postAPI } from '../../Services/Api';
import { PRIORITY_RANGE_MINIMUM, PRIORITY_RANGE_MAXIMUM } from '../../Constants/Constants';
export const PriorityComponent = props => {
  const { request, priority, status } = props;
  const [value, setValue] = useState(priority);
  const [visible, setVisible] = useState(false);
  const handleModalCancel = () => {
    setVisible(false);
    setValue(priority);
  };
  const isDisabled = status === REQUEST_STATUS_VALUES.COMPLETED || status === REQUEST_STATUS_VALUES.FAILED;

  const handleIncrease = () => {
    if (value < PRIORITY_RANGE_MAXIMUM) {
      setValue(Math.min(PRIORITY_RANGE_MAXIMUM, value + 1));
    }
    setVisible(true);
  };

  const handleDecrease = () => {
    if (value > PRIORITY_RANGE_MINIMUM + 1) {
      setValue(Math.max(PRIORITY_RANGE_MINIMUM + 1, value - 1));
    }
    setVisible(true);
  };

  const handleInputChange = inputValue => {
    const parsedValue = parseInt(inputValue, 10);
    if (!isNaN(parsedValue)) {
      setValue(Math.min(PRIORITY_RANGE_MAXIMUM, Math.max(PRIORITY_RANGE_MINIMUM + 1, parsedValue)));
    }
  };

  const priorityHandler = async () => {
    try {
      const url = interpolate(UPDATE_REQUEST_PRIORITY, [request.request_id]);
      let user = JSON.parse(sessionStorage.getItem('user'));
      const payload = {
        priority: value,
        user_name: user?.email
      };
      await postAPI(url, payload);
      setVisible(false);
      message.success('Priority updated successfully');
    } catch (err) {
      setValue(priority);
      handleModalCancel();
    }
  };

  return (
    <div>
      <Modal
        visible={visible}
        onCancel={handleModalCancel}
        title={`Change Priority for Request`}
        footer={[
          <Button key='cancel' onClick={handleModalCancel}>
            Cancel
          </Button>,
          <Button key='change' type='primary' danger onClick={priorityHandler}>
            Change
          </Button>
        ]}>
        <p>
          Are you sure you want to change the priority for request <strong>{request.request_id}</strong>?
        </p>
      </Modal>
      <div>
        <div className='flex-center'>
          <Button onClick={handleDecrease} disabled={isDisabled} className='leftbtn'>
            -
          </Button>
          <InputNumber
            value={value}
            onChange={handleInputChange}
            onPressEnter={() => setVisible(true)}
            controls={false}
            className='prioity-input'
            disabled={isDisabled}
          />
          <Button onClick={handleIncrease} disabled={isDisabled} className='rightbtn'>
            +
          </Button>
        </div>
      </div>
    </div>
  );
};
