import { useEffect, useState } from 'react';
import { Modal, Button, Typography, message } from 'antd';
import AlertIcon from '../Assets/alert-icon.svg';
import { postAPI } from '../Services/Api';
import { interpolate } from '../Utils/HelperFunctions';
import { UPDATE_REQUEST_JOB_ID } from '../Constants/Constants';

function AlertModal({ visible, handleIsVisible, requestId, jobId }) {
  const [isInvalidId, setIsInvalidId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const handleReplaceId = async () => {
    try {
      let user = JSON.parse(sessionStorage.getItem('user'));
      const payload = {
        job_id: jobId,
        user_name: user?.email
      };
      setLoading(true);
      const url = interpolate(UPDATE_REQUEST_JOB_ID, [requestId]);
      await postAPI(url, payload);
      setLoading(false);
      handleIsVisible();
      message.success('FXS ID replaced successfully');
      window.location.reload();
    } catch (err) {
      setLoading(false);
      setIsInvalidId(true);
      setErrMessage(err.response.data || 'Something went wrong');
      console.log(err, 'err');
    }
  };

  const handleCloseModal = () => {
    handleIsVisible();
  };

  useEffect(() => {
    setIsInvalidId(false);
  }, [visible]);

  return (
    <Modal visible={visible} onCancel={handleCloseModal} footer={null} width={350} closable={false}>
      <div className='modal-container'>
        <div style={{ flex: 1 }}>
          <div className='flex-center'>
            <img src={AlertIcon} alt='alert' />
          </div>
          <br />
          <div className='flex-col flex-center'>
            {isInvalidId && (
              <>
                <Typography.Title level={4}>Alert!</Typography.Title>
                <Typography.Text>{errMessage}</Typography.Text>
              </>
            )}
            {!isInvalidId && (
              <>
                <Typography.Title level={4}>Are you sure?</Typography.Title>
                <Typography.Text>Are you sure you want to replace FXS job id?</Typography.Text>
              </>
            )}
          </div>
        </div>
        <div>
          {isInvalidId && (
            <Button onClick={handleCloseModal} danger>
              Try Again
            </Button>
          )}
          {!isInvalidId && (
            <>
              <Button onClick={handleCloseModal} danger>
                Try Again
              </Button>
              <Button type='primary' style={{ marginLeft: '10px' }} onClick={handleReplaceId} loading={loading}>
                Replace
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default AlertModal;
