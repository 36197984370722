import React from 'react';
import { Col, Row } from 'antd';
import HeaderContent from './HeaderContent';

const LayoutComponent = ({ children }) => {
  return (
    <>
      <HeaderContent />

      <Row className='container'>
        <Col xs={{ span: 22, offset: 1 }}>
          <div>{children}</div>
        </Col>
      </Row>
    </>
  );
};

export default LayoutComponent;
