import { ANNOTATION_LINK, FXS_LINK } from './URLs';

export const PENDING = 'PENDING';
export const POLING = 'POLING';
export const COMPLETED = 'COMPLETED';
export const FAILED = 'FAILED';
export const PAUSED = 'PAUSED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const ALL = 'ALL';
export const REQUEST_RESULTS = 0;
export const REQUEST_RESULTS_METADATA = 1;

export const PRIORITY_RANGE_MINIMUM = 0;
export const PRIORITY_RANGE_MAXIMUM = 100;

export const REQUEST_STATUS = {
  [PENDING]: 'Pending',
  // [POLING]: 'Poling',
  [COMPLETED]: 'Completed',
  [FAILED]: 'Failed',
  [PAUSED]: 'Paused',
  [IN_PROGRESS]: 'In Progress'
};

export const REQUEST_STATUS_VALUES = {
  [PENDING]: 1,
  [IN_PROGRESS]: 2,
  [POLING]: 3,
  [COMPLETED]: 4,
  [FAILED]: 5,
  [PAUSED]: 6
};

export const GET_STATUS = {
  1: REQUEST_STATUS.PENDING,
  2: REQUEST_STATUS[IN_PROGRESS],
  3: REQUEST_STATUS.POLING,
  4: REQUEST_STATUS.COMPLETED,
  5: REQUEST_STATUS.FAILED,
  6: REQUEST_STATUS.PAUSED
};

export const GET_STATUS_COLORS = {
  1: 'purple',
  2: 'cyan',
  3: 'orange',
  4: 'green',
  5: 'red',
  6: 'blue'
};

export const STEP_NAMES = {
  FETCHING_IMAGERY: 'fetching_imagery',
  PERFORM_TILES_GENERATION: 'perform_tiles_generation',
  PERFORM_CANOPY_COVER_CHECK: 'perform_canopy_cover_check',
  PERFORM_ML_EXTRACTION_HARDSCAPE: 'perform_ml_extraction_hardscape',
  PERFORM_QC: 'perform_qc',
  PERFORM_QA: 'perform_qa',
  PERFORM_ML_EXTRACTION_LAWN_EDGE: 'perform_ml_extraction_lawn_edge',
  PERFORM_LAWN_EDGE_QC: 'perform_lawn_edge_qc',
  PERFORM_LAWN_EDGE_QA: 'perform_lawn_edge_qa',
  PARCEL_CORRECTION_QC: 'perform_parcel_correction_QC',
  PARCEL_CORRECTION_QA: 'perform_parcel_correction_QA'
};

export const STEP_LINKS = {
  [STEP_NAMES.FETCHING_IMAGERY]: FXS_LINK,
  [STEP_NAMES.PERFORM_TILES_GENERATION]: FXS_LINK,
  [STEP_NAMES.PERFORM_CANOPY_COVER_CHECK]: ANNOTATION_LINK,
  [STEP_NAMES.PERFORM_ML_EXTRACTION_HARDSCAPE]: FXS_LINK,
  [STEP_NAMES.PERFORM_QC]: ANNOTATION_LINK,
  [STEP_NAMES.PERFORM_QA]: ANNOTATION_LINK,
  [STEP_NAMES.PERFORM_ML_EXTRACTION_LAWN_EDGE]: FXS_LINK,
  [STEP_NAMES.PERFORM_LAWN_EDGE_QC]: ANNOTATION_LINK,
  [STEP_NAMES.PERFORM_LAWN_EDGE_QA]: ANNOTATION_LINK,
  [STEP_NAMES.PARCEL_CORRECTION_QA]: ANNOTATION_LINK,
  [STEP_NAMES.PARCEL_CORRECTION_QC]: ANNOTATION_LINK
};
export const UPDATE_REQUEST_JOB_ID= '/requests/%s/imagery_step';